<template>
  <div class="google-recaptcha-holder">
    <div
      id="g-recaptcha"
      class="g-recaptcha"
      :data-sitekey="sitekey"></div>
    <div class="google-recaptcha">
      <span v-html="$t('google.recaptcha.first')"></span>
      <a href="https://policies.google.com/privacy" target="_blank"><span v-html="$t('google.recaptcha.privacy')"></span></a>
      <span v-html="$t('google.recaptcha.and')"></span>
      <a href="https://policies.google.com/terms" target="_blank"><span v-html="$t('google.recaptcha.terms')"></span></a>
      <span v-html="`$t('google.recaptcha.apply')`"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'recaptcha',
  data() {
    return {
      sitekey: '',
      widgetId: 0,
    };
  },
  methods: {
    async FETCH_BOX_SETTINGS() {
      await this.$store.dispatch('setBoxSettings');
      return this.$store.getters.boxSettings;
    },

    execute() {
      window.grecaptcha.execute(this.widgetId);
    },

    reset() {
      window.grecaptcha.reset(this.widgetId);
    },

    async render() {
      // fetch settings
      await this.FETCH_BOX_SETTINGS().then((settings) => {
        // set key
        this.sitekey = settings.google.recaptcha;

        // render
        if (window.grecaptcha) {
          // render
          this.widgetId = window.grecaptcha.render('g-recaptcha', {
            sitekey: this.sitekey,
            size: 'invisible',
            // the callback executed when the user solve the recaptcha
            callback: (googleRecaptchaResponse) => {
              // emit an event called verify with the response as payload
              this.$emit('verify', googleRecaptchaResponse);
              // reset the recaptcha widget so you can execute it again
              this.reset(this.widgetId);
            },
          });
        }
      });
    },

    error(message) {
      this.$notify({
        message: this.$t(`${message}`),
        type: 'error',
        position: 'bottom-right',
      });
    },
  },
  mounted() {
    // render the recaptcha widget when the component is mounted
    this.render();
  },
};
</script>
